import React from "react"
import "../styles/footer.css"

const Footer = () => {
  return (
    <div className={"footer"}>
      <div className={"col"}>
        <div>
          <p>
            <strong>Mera Elvision AB</strong>
          </p>
          <p>Ågestavägen 10 </p>
          <p>141 38 Huddinge</p>
        </div>
        <p>
          <strong>Orgnr:</strong> 556815-9700
        </p>
      </div>
      <div className={"col"}>
        <div>
          <p>
            <strong>Mats:</strong> 076-200 00 50
          </p>
          <p>
            <strong>Michael:</strong> 070-828 81 16
          </p>
        </div>
        <div style={{ margin: "6px 0" }}>
          <p>
            <strong>Öppettider: </strong>
          </p>

          <p>Mån-Tors 7-17</p>
          <p> Fredagar 7-16</p>
        </div>
        <a
          href="https://www.facebook.com/MeraElvisionAb/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Besök vår Facebook"
          className="social-btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-facebook"
          >
            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
          </svg>
        </a>
      </div>
    </div>
  )
}

export default Footer
