import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Nav from "./Nav"
import Footer from "./Footer"
import "../styles/index.css"

const Layout = ({ children }) => {
  const handleKeyDown = () => {
    document.body.classList.remove("using-mouse")
    document.body.classList.add("using-keyboard")
  }
  const handleMouseDown = () => {
    document.body.classList.add("using-mouse")
    document.body.classList.remove("using-keyboard")
  }
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("mousedown", handleMouseDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("mousedown", handleMouseDown)
    }
  }, [])
  return (
    <div>
      <Helmet>
        <title>Mera Elvision AB</title>
        <meta
          name="description"
          content="Din elektriker i Stockholm, Vi erbjuder alla typer av elinstallationer inom stockholmsområdet."
        />
      </Helmet>
      <Nav />
      <div className="content-wrapper">
        <div className="content-limits">{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
