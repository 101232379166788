import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import logo from "../assets/logo.png"
import "../styles/nav.css"

export default function Nav() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    const getWindowDimensions = () => {
      const { innerWidth: width, innerHeight: height } = window
      return {
        width,
        height,
      }
    }
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }
    setWindowDimensions(getWindowDimensions())
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const navItems = [
    { path: "/", title: "Hem" },
    { path: "/projekt", title: "Projekt" },
    { path: "/om-oss", title: "Om oss" },
    { path: "/tjanster", title: "Tjänster" },
    { path: "/kontakt", title: "Kontakt" },
  ]

  const [isActive, setIsActive] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [fades, setFades] = useState(navItems.map(() => ({})))

  const { width } = windowDimensions

  const handleClick = () => {
    if (width <= 769) {
      setToggle(!toggle)
      if (isActive) {
        setFades(navItems.map(() => ({})))
      } else {
        setFades(
          navItems.map((n, i) => ({
            animation: `navLinkFade 0.5s ease forwards 0.${i + 1}s`,
          }))
        )
      }
      setIsActive(!isActive)
    } else if (isActive) {
      setIsActive(false)
    }
  }

  const handleLogoClick = () => {
    if (isActive) {
      setIsActive(false)
      setToggle(!toggle)
      setFades(navItems.map(() => ({})))
    }
  }

  return (
    <>
      <div className={` ${isActive ? "nav-blur" : ""}`}></div>
      <nav className={"main-nav"}>
        <Link
          to="/"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={handleLogoClick}
        >
          <img
            width={"150px"}
            className={"main-nav__img"}
            src={logo}
            alt="Logo"
          />
        </Link>
        <ul className={`main-nav__links ${isActive ? "nav-active" : ""}`}>
          {navItems.map((item, i) => (
            <li key={i}>
              <Link
                className={"main-nav__link"}
                tabIndex={0}
                role="button"
                onClick={handleClick}
                onKeyDown={() => {}}
                style={fades[i]}
                to={item.path}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
        <div
          tabIndex={0}
          role="button"
          aria-label="Meny"
          className={`burger ${toggle ? "toggle" : ""}`}
          onClick={handleClick}
          onKeyDown={() => {}}
        >
          <div className={"line1"}></div>
          <div className={"line2"}></div>
          <div className={"line3"}></div>
        </div>
      </nav>
    </>
  )
}
